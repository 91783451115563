// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-article-404-template-js": () => import("./../src/templates/Article404Template.js" /* webpackChunkName: "component---src-templates-article-404-template-js" */),
  "component---src-templates-game-template-js": () => import("./../src/templates/GameTemplate.js" /* webpackChunkName: "component---src-templates-game-template-js" */),
  "component---src-templates-meo-template-js": () => import("./../src/templates/MeoTemplate.js" /* webpackChunkName: "component---src-templates-meo-template-js" */),
  "component---src-templates-room-template-1-js": () => import("./../src/templates/RoomTemplate1.js" /* webpackChunkName: "component---src-templates-room-template-1-js" */),
  "component---src-templates-room-template-2-js": () => import("./../src/templates/RoomTemplate2.js" /* webpackChunkName: "component---src-templates-room-template-2-js" */),
  "component---src-templates-room-template-3-js": () => import("./../src/templates/RoomTemplate3.js" /* webpackChunkName: "component---src-templates-room-template-3-js" */),
  "component---src-templates-room-template-4-js": () => import("./../src/templates/RoomTemplate4.js" /* webpackChunkName: "component---src-templates-room-template-4-js" */),
  "component---src-templates-room-template-5-js": () => import("./../src/templates/RoomTemplate5.js" /* webpackChunkName: "component---src-templates-room-template-5-js" */),
  "component---src-templates-room-template-6-js": () => import("./../src/templates/RoomTemplate6.js" /* webpackChunkName: "component---src-templates-room-template-6-js" */),
  "component---src-templates-room-template-7-js": () => import("./../src/templates/RoomTemplate7.js" /* webpackChunkName: "component---src-templates-room-template-7-js" */),
  "component---src-templates-room-template-8-js": () => import("./../src/templates/RoomTemplate8.js" /* webpackChunkName: "component---src-templates-room-template-8-js" */),
  "component---src-templates-the-operation-game-template-js": () => import("./../src/templates/TheOperationGameTemplate.js" /* webpackChunkName: "component---src-templates-the-operation-game-template-js" */),
  "component---src-templates-faq-template-js": () => import("./../src/templates/FaqTemplate.js" /* webpackChunkName: "component---src-templates-faq-template-js" */),
  "component---src-templates-frank-slideshow-template-js": () => import("./../src/templates/FrankSlideshowTemplate.js" /* webpackChunkName: "component---src-templates-frank-slideshow-template-js" */),
  "component---src-templates-quiz-template-js": () => import("./../src/templates/QuizTemplate.js" /* webpackChunkName: "component---src-templates-quiz-template-js" */),
  "component---src-templates-memory-template-js": () => import("./../src/templates/MemoryTemplate.js" /* webpackChunkName: "component---src-templates-memory-template-js" */),
  "component---src-templates-kp-start-page-template-js": () => import("./../src/templates/KpStartPageTemplate.js" /* webpackChunkName: "component---src-templates-kp-start-page-template-js" */),
  "component---src-templates-kp-slideshow-template-js": () => import("./../src/templates/KpSlideshowTemplate.js" /* webpackChunkName: "component---src-templates-kp-slideshow-template-js" */),
  "component---src-templates-lucas-start-page-template-js": () => import("./../src/templates/LucasStartPageTemplate.js" /* webpackChunkName: "component---src-templates-lucas-start-page-template-js" */),
  "component---src-templates-lucas-slideshow-template-js": () => import("./../src/templates/LucasSlideshowTemplate.js" /* webpackChunkName: "component---src-templates-lucas-slideshow-template-js" */),
  "component---src-templates-mathilda-slideshow-template-js": () => import("./../src/templates/MathildaSlideshowTemplate.js" /* webpackChunkName: "component---src-templates-mathilda-slideshow-template-js" */),
  "component---src-templates-my-hospital-book-template-js": () => import("./../src/templates/MyHospitalBookTemplate.js" /* webpackChunkName: "component---src-templates-my-hospital-book-template-js" */),
  "component---src-templates-podcast-template-js": () => import("./../src/templates/PodcastTemplate.js" /* webpackChunkName: "component---src-templates-podcast-template-js" */),
  "component---src-templates-start-page-template-js": () => import("./../src/templates/StartPageTemplate.js" /* webpackChunkName: "component---src-templates-start-page-template-js" */),
  "component---src-templates-text-page-template-js": () => import("./../src/templates/TextPageTemplate.js" /* webpackChunkName: "component---src-templates-text-page-template-js" */),
  "component---src-templates-teenage-info-template-js": () => import("./../src/templates/TeenageInfoTemplate.js" /* webpackChunkName: "component---src-templates-teenage-info-template-js" */),
  "component---src-templates-vo-template-js": () => import("./../src/templates/VoTemplate.js" /* webpackChunkName: "component---src-templates-vo-template-js" */),
  "component---src-templates-blog-start-page-template-js": () => import("./../src/templates/BlogStartPageTemplate.js" /* webpackChunkName: "component---src-templates-blog-start-page-template-js" */),
  "component---src-templates-blog-about-template-js": () => import("./../src/templates/BlogAboutTemplate.js" /* webpackChunkName: "component---src-templates-blog-about-template-js" */),
  "component---src-templates-blog-post-template-js": () => import("./../src/templates/BlogPostTemplate.js" /* webpackChunkName: "component---src-templates-blog-post-template-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-chitchat-cms-js": () => import("./../src/pages/chitchat-cms.js" /* webpackChunkName: "component---src-pages-chitchat-cms-js" */)
}

