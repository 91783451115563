module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/opt/build/repo/intl","languages":["en","sv"],"defaultLanguage":"sv","redirect":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"modulePath":"/opt/build/repo/src/cms/cms.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"2","matomoUrl":"https://stats.mediprep.org","siteUrl":"https://narkoswebben.se","matomoPhpScript":"matomo.php","matomoJsScript":"matomo.js","requireConsent":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#48A0F4","showSpinner":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Narkoswebben","short_name":"Narkoswebben","start_url":"/?source=pwa","background_color":"#C70007","theme_color":"#C70007","display":"standalone","icon":"src/images/app-icons/nw_logo_cube_sv.svg","crossOrigin":"use-credentials","lang":"sv","localize":[{"name":"Anaesthesia Web","short_name":"Anaesthesia Web","start_url":"/en/","lang":"en","icon":"src/images/app-icons/nw_logo_cube_en.svg"}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
