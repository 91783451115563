import merge from "lodash/merge"
import get from "lodash/get"
import isEmpty from "lodash/isEmpty"

// This function should be used to make sure that the store state
// is backward compatible when changes to its structure are made
export default function getTransformedStoreState(initialState, persistedState) {
  if (isEmpty(persistedState)) {
    return initialState
  }

  if (typeof get(persistedState, "meo.persons.child.id") === "undefined") {
    persistedState.meo.persons = initialState.meo.persons
  }

  if (typeof persistedState.hideCiceron !== "object") {
    persistedState.hideCiceron = initialState.hideCiceron
  }

  if (!persistedState.cookies || (persistedState.cookies && persistedState.cookies.v !== initialState.cookies.v)) {
    persistedState.cookies = initialState.cookies
  }

  // Merge initialState with persistedState in case new keys have been added
  return merge(initialState, persistedState)
}
