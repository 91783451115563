import React, { createContext, useCallback, useContext, useState, useRef } from "react"

import trackEvent, { getEventTrackingData } from "../utils/track-event"

export const modalContext = createContext()

export function ModalProvider({ ...props }) {
  const { Provider } = modalContext
  const [state, setState] = useState({ activeModal: null, activeModalProps: null })
  const modalTriggerRef = useRef()
  return (
    <Provider
      {...props}
      value={{
        ...state,
        openModal: useCallback((event, modalName, modalProps = null) => {
          if ((typeof event !== "object" && event !== null) || typeof modalName !== "string") {
            throw new Error("Incorrect arguments passed to function")
            return
          }
          trackEvent(getEventTrackingData({ open: true, modalName, modalProps }))
          modalTriggerRef.current = event ? event.currentTarget : null
          setState({ ...state, activeModal: modalName, activeModalProps: modalProps })
          document.body.style.overflow = "hidden"
        }, []),
        closeModal: useCallback(() => {
          setState((currentState) => {
            trackEvent(getEventTrackingData({ modalName: currentState.activeModal, modalProps: currentState.activeModalProps }))
            return { ...currentState, activeModal: null, activeModalProps: null }
          })
          document.body.style.overflow = "visible"

          setTimeout(() => {
            const modalTrigger = modalTriggerRef.current
            if (modalTrigger) {
              if (modalTrigger.nodeName.toLowerCase() === "g") {
                document.querySelector(`a[*|href="#${modalTrigger.id}"]`).focus()
              } else {
                modalTrigger.focus()
              }
              modalTriggerRef.current = null
            }
          })
        }, []),
      }}
    ></Provider>
  )
}

export function useModal() {
  return useContext(modalContext)
}
