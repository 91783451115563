export const getEventTrackingData = (params) => {
  if (params.modalName) {
    if (params.modalName === "video") {
      return {
        category: "Video",
        action: `${params.open ? "Open" : "Close"} Modal`,
        name: params.modalProps.title,
      }
    }

    if (params.modalName === "flipCard") {
      return {
        category: "Clown Card",
        action: `${params.open ? "Open" : "Close"} Modal`,
        name: params.modalProps.clown.clown_name,
      }
    }

    return {
      category: "Modal",
      action: `${params.open ? "Open" : "Close"} Modal`,
      name: params.modalName,
    }
  }

  return params
}

export default function trackEvent({ category, action, name, value }) {
  if (typeof window._paq !== "undefined" && category && action && name) {
    const ucName = `${name.charAt(0).toUpperCase()}${name.slice(1)}`
    window._paq.push(["trackEvent", category, action, ucName].concat(value || []))
  }
}
