import React, { createContext, useContext, useCallback, useState, useEffect } from "react"
import produce from "immer"

import cookieConfig from "../cookie-config.json"
import getTransformedStoreState from "../utils/get-transformed-store-state"

const persistedStateKey = "narkoswebben/store"

const initialState = {
  animationsEnabled: true,
  isEasyReadEnabled: false,
  audioDescriptionEnabled: false,
  hideBrowserUpdateMessage: false,
  cookies: {
    ...cookieConfig.cookies.reduce((acc, curr) => ({ ...acc, [curr.id]: false }), {}),
    hide: false,
    v: 4,
  },
  hideCiceron: {
    start: false,
    adults: false,
    room1: false,
    room2: false,
    room3: false,
    room4: false,
    room5: false,
    room6: false,
    room7: false,
    room8: false,
  },
  meo: {
    furniture: {
      lamp: "Lampa_1.svg",
      optable: 1,
      chair: "Stol_1.svg",
      floor: null,
      wall: null,
    },
    persons: {
      child: { id: 0, img: "", sex: "" },
      parent: { id: 0, img: "", sex: "" },
      surgeon: { id: 0, img: "", sex: "" },
      anadoc: { id: 0, img: "", sex: "" },
      nurse: { id: 0, img: "", sex: "" },
    },
    xray: {
      sliderPos: 1,
      screenOn: false,
    },
    ana: {},
    dialogue: {
      intro: 0,
    },
  },
  vildingQuiz1: 0,
  vildingQuiz2: 0,
  vildingQuiz3: 0,
  vildingQuiz4: 0,
  vildingMemory1: 0,
  vildingMemory2: 0,
  vildingCrossword1: 0,
  vildingCrossword2: 0,
  vildingWordSearch: 0,
  vildingWhatBelongsTogether: 0,
}

export const storeContext = createContext()

export function StoreProvider(props) {
  const Provider = storeContext.Provider
  let persistedState = {}
  const [isInitialized, setIsInitialized] = useState(false)
  const isBrowser = typeof window !== "undefined"

  // Get state from localStorage if set
  if (isBrowser) {
    try {
      persistedState = JSON.parse(window.localStorage.getItem(persistedStateKey))
    } catch (e) {}
  }

  const [state, setState] = useState(getTransformedStoreState(initialState, persistedState))

  useEffect(() => {
    // Save new state in localStorage
    if (isBrowser) {
      try {
        window.localStorage.setItem(persistedStateKey, JSON.stringify(state))
      } catch (e) {}
    }
  }, [state])

  // Workaround for SSR
  useEffect(() => {
    if (typeof window !== "undefined" && !isInitialized) {
      try {
        persistedState = JSON.parse(window.localStorage.getItem(persistedStateKey))
      } catch (e) {}

      setState(getTransformedStoreState(initialState, persistedState))
      setIsInitialized(true)
    }
  })

  return (
    <Provider
      {...props}
      value={[
        state,
        useCallback(updater => {
          setState(produce(updater))
        }, []),
      ]}
    />
  )
}

export function useStore() {
  return useContext(storeContext)
}
