import React from "react"
import PageWrapper from "./src/PageWrapper"

import "fontsource-baloo-2/700.css"
import "fontsource-source-sans-pro"
import "fontsource-source-sans-pro/600.css"
import "fontsource-noto-sans"

export const wrapRootElement = function RootWrapper({ element }) {
  return <PageWrapper>{element}</PageWrapper>
}
