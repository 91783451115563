import React from "react"
import { StoreProvider } from "./hooks/store"
import { ModalProvider } from "./hooks/modal"
import { ThemeProvider, theme } from "./theme"

export default function PageWrapper({ children }) {
  return (
    <StoreProvider>
      <ModalProvider>
        <ThemeProvider theme={theme}>{children}</ThemeProvider>
      </ModalProvider>
    </StoreProvider>
  )
}
